#search-buttun {
    position: absolute !important;
    top: 0px !important;
    right: 0 !important;
    height: 47px !important;
    width: 110px !important;
    background: #15678f !important;
    border-radius: 0 !important;
    border-color: #15678f !important;
}

#set-search {
    color: whitesmoke !important;
}

.list-item-container {
    padding: 8px;
    border-bottom: 2px solid #ffffff;
    background: whitesmoke;
    /* max-height: 400px !important; */
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
}

.right {
    flex: 2 !important;
    font-size: 20px !important;
    margin-top: -6px !important;
    font-weight: bold !important;
    /* float: right; */
    /* position: fixed !important; */
    right: 520px !important;
    /* top: 300px; */
}

/*   
   .center {
    margin: 3px;
    } */
.info {
    background: #342f2f !important;
    color: whitesmoke !important;
    border-radius: 53px !important;
    font-size: 17px !important;
    width: 23px !important;
    margin-top: 7px !important;
    cursor: pointer;
    float: right;
}

#name-info {
    float: left;
    text-align: initial;
}

#myAccount {
    background: black;
    border: 2px solid rgb(102, 102, 102);
    padding: 55px;
    cursor: pointer;
    opacity: 0.2;
}

/* #myAccount {myPlanPay
   
    border: 2px solid rgb(105, 91, 231);
    padding: 55px;
    cursor: pointer;
    opacity: 0.8;
    color: rgb(105, 91, 231) !important;
} */

#myAccount:hover {
    background: rgb(55, 47, 131);
    border: 2px solid rgb(255, 255, 255);
    padding: 55px;
    cursor: pointer;
    opacity: 1;
}

#myPlanPay {
    background: black;
    border: 2px solid rgb(102, 102, 102);
    padding: 55px;
    cursor: pointer;
    opacity: 0.2;
}

/* #myAccount {
   
    border: 2px solid rgb(105, 91, 231);
    padding: 55px;
    cursor: pointer;
    opacity: 0.8;
    color: rgb(105, 91, 231) !important;
} */

#myPlanPay:hover {
    background: rgb(184, 89, 26);
    border: 2px solid rgb(255, 255, 255);
    padding: 55px;
    cursor: pointer;
    opacity: 1;
}


#myDrugList {
    background: black;
    border: 2px solid rgb(102, 102, 102);
    padding: 55px;
    cursor: pointer;
    opacity: 0.2;
}

#myDrugList:hover {
    background: rgb(54, 87, 119);
    border: 2px solid rgb(255, 255, 255);
    padding: 55px;
    cursor: pointer;
    opacity: 1;
}

#myPlan {
    background: black;
    border: 2px solid rgb(102, 102, 102);
    padding: 55px;
    cursor: pointer;
    opacity: 0.2;
}

#myPlan:hover {
    background: rgb(52, 85, 54);
    border: 2px solid rgb(255, 255, 255);
    padding: 55px;
    cursor: pointer;
    opacity: 1;
}

#myPaymentHistory {
    background: black;
    border: 2px solid rgb(102, 102, 102);
    padding: 55px;
    cursor: pointer;
    opacity: 0.2;
}

#myPaymentHistory:hover {
    background: rgb(55, 42, 77);
    border: 2px solid rgb(255, 255, 255);
    padding: 55px;
    cursor: pointer;
    opacity: 1;
}

#keys {
    color: #82878bfa;
    font-size: 14px;
    font-family: sans-serif;
    font-weight: bold;
    margin-top: 10px;
}

#vals {
    color: #557287fa;
    font-size: 14px;
    font-family: sans-serif;
    font-weight: bold;
    margin-top: 10px;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}

#collapseTwo {
    margin-top: -20px;
    margin-left: 50px !important;
}

#headingTwo {
    margin-left: -1px;
    padding-bottom: 6px
}

#keyss {
    color: #82878bfa;
    font-size: 12px;
    font-family: sans-serif;
    font-weight: bold;
    margin-top: 10px;
}

#collapseDoseForm {
    margin-top: -18px;
}

btn-danger {
    color: #fff !important;
    background-color: #9d322dfa !important;
    border-color: #9d322dfa !important;
}

.react-confirm-alert-body>h1 {
    margin-top: 15px !important;
    font-size: 20px !important;
    text-align: center !important;
    color: #13759dfc !important;
}

.react-confirm-alert-button-group {
    /* display: -moz-flex;
        display: -ms-flex;
        display: -o-flex; */
    display: inherit !important;
    justify-content: initial !important;
    margin-top: 50px !important;
    text-align: center !important;
}

.react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif !important;
    width: 400px !important;
    height: 235px !important;
    padding: 46px !important;
    text-align: left;
    /* background: #05668E!important; */
    border-radius: 10px !important;
    box-shadow: 0 20px 75px rgb(0 0 0 / 13%) !important;
    color: rgb(248, 244, 244) !important;
}

button#deletNo {
    background: #b7b7bf;
    width: 92px;
    padding: 10px;
}

button#deleteYes {
    width: 92px;
    background: #c7342d;
    padding: 10px;
}

#card-img-top {
    width: 200px;
    margin-top: 10px;
    margin-left: 27px;
    background-color: #fff;
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    border-color: rgba(223, 225, 229, 0);
}

#module-card {
    margin: 20px;
    width: 250px;
}

#module-card1 {
    margin: 20px;
    width: 250px;
}

#set-add-on {
    width: 258px;
    margin: 25px;
}

#paymentDetails {
    color: #242222;
    float: left;
    font-size: 13px;
    margin-left: 25px;
    margin-top: 10px;
}

#subcribe-button {
    border-radius: 0px;
}

.is-active {
    color: #fff !important;
    background-color: #a6c550 !important;
    border-color: #a6c550 !important;
    border-radius: 3px !important;
}

/* a.is-active{
        color: #fff !important;
        background-color: #a6c550 !important;
        border-color: #a6c550 !important;
        border-radius: 3px !important;
    } */


section#sub-plan-caontainer {
    margin-top: -190px;
}

#update-setting {
    border: 1px solid #A7CA56;
    background: #A7CA56;
    border-radius: 50px;
    margin-left: 5px;
}

button#view-reciept {
    padding: 8px;
}

div#set-modules {
    margin-top: 0px;
}

.lead {
    font-size: 15px !important;
    font-weight: 300 !important;
    margin: 4px !important;
}

img#set-plan-img {
    border: 2px solid;
    width: 200px;
    margin: auto;
    height: 140px;
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    border-color: rgba(223, 225, 229, 0);
    border-radius: 100px
}

.card-header {
    padding: 0.75rem 1.25rem !important;
    margin-bottom: 0 !important;
    background-color: rgb(64 61 61 / 10%) !important;
    border-bottom: 0px solid rgba(0, 0, 0, 0.125) !important;
    border-radius: 0px !important
}

button#promo-buttons {
    background: white;
    color: #17a2b8;
    border: 2px solid #17a2b8;
    color: #17a2b8;
    border-radius: 20px;
    width: 101px;
    padding: 4px;
    margin-left: 117px;
}

button#promo-buttons:hover {
    background: #17a2b8;
    color: white;
}



button#promo-buttons-remove {
    background: white;
    color: orangered;
    border: 2px solid orangered;
    color: orangered;
    border-radius: 20px;
    width: 101px;
    padding: 4px;
    margin-left: -25px;
}

button#promo-buttons-remove:hover {
    background: orangered;
    color: white;
}


span#danger-promo {
    color: #e24444;
    /* font-size: 12px; */
}

.search input {
    height: 65px !important;
    text-indent: 25px;
    border: 2px solid #d6d4d4;
    font-size: 16px !important;
    font-weight: bold;
    color: #201d1dc2 !important;
}

#search-buttun {
    position: absolute !important;
    top: 0px !important;
    right: 0 !important;
    height: 65px !important;
    width: 110px !important;
    background: #15678f !important;
    border-radius: 0 !important;
    border-color: #15678f !important;
}

#rearch-result:hover {
    text-decoration: underline;
}

#rearch-result-desc:hover {
    text-decoration: underline;
}

#popup-heading-hover {
    color: #a6c550;
}

#popup-heading-hover:hover {
    color: #15678f;
}

#Gen-heading-hover p {
    text-decoration: underline;
}



#Gen-heading-hover {
    color: #6fbb6f;
}

#Gen-heading-hover:hover {
    color: #15678f;
    /* text-decoration: underline; */

}


button:disabled,
button[disabled] {
    border: 1px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
    cursor: not-allowed !important;
}

#collapseCitaction {
    margin-top: 5px !important;
    padding-left: 62px;
}

button#static-search {
    background: #d9d9d9;
    /* border: 6px; */
    border-radius: 0px;
    width: 42px;
    border: 0px;
    color: white;
    height: 34px;
}

input#myInput {
    width: 240px;
}

img#set-plan-img {
    border: 2px solid;
    width: 145px;
    margin: auto;
    height: 145px;
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    border-color: rgba(223, 225, 229, 0);
}

#card-img-top {
    width: 150px;
    border-radius: 100px;
    margin-top: 10px;
    margin-left: 53px;
    background-color: #fff;
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    border-color: rgba(223, 225, 229, 0);
}

/* .card-header */
#set-search-fav {
    color: #fed300;
    /* margin-left: 100px; */
    cursor: pointer;
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
}

#set-search-fav:hover {
    color: #f5f4f3;
    /* margin-left: 100px; */
    cursor: pointer;
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
}

#edit-emails {
    color: #15678F;
    font-size: 12px;
    cursor: pointer;
    margin-left: 0px;
}

#edit-emails:hover {
    text-decoration: underline;
}

/* ReactModal__Overlay.ReactModal__Overlay--after-open {
    background: rgb(108, 107, 107) !important;
    opacity: 0.8 !important;
} */

.ReactModal__Overlay {
    -webkit-perspective: 600 !important;
    perspective: 600 !important;
    opacity: 0 !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    background-color: rgba(0, 0, 0, 0.5) !important;

}

.ReactModal__Overlay--after-open {
    z-index: 999 !important;
    opacity: 1 !important;
    transition: opacity 150ms ease-out !important;
}

.ReactModal__Content {
    -webkit-transform: scale(0.5) rotateX(-30deg) !important;
    transform: scale(0.5) rotateX(-30deg) !important;
    margin-right: 50% !important;
}

.ReactModal__Content--after-open {
    -webkit-transform: scale(1) rotateX(0deg) !important;
    transform: scale(1) rotateX(0deg) !important;
    transition: all 150ms ease-in !important;
}

.ReactModal__Overlay--before-close {
    opacity: 0 !important;
}

.ReactModal__Content--before-close {
    -webkit-transform: scale(0.5) rotateX(30deg) !important;
    transform: scale(0.5) rotateX(30deg) !important;
    transition: all 150ms ease-in !important;
}

.ReactModal__Content.modal-dialog {
    border: none !important;
    background-color: transparent !important;
}

.btn-success {
    background: #A7CA56 !important;
    ;
    border: 1px solid #A7CA56 !important;
}


.btn-warning {
    color: whitesmoke !important;
    background-color: #a7ca56 !important;
    border-color: #a7ca56 !important;
}

#myTable th {
    position: sticky;
    position: -webkit-sticky;
    top: -2px;
    background: #dddbdf;
    margin-top: -1px !important;
    color: black !important;
    padding: 6px !important;
    font-family: Arial, Helvetica, sans-serif;
}


#About .rounded-circle {
    border-radius: 50% !important;
    height: 170px !important;
    width: 165px !important;
    padding: 4px !important;
    /* border: 3px solid #e8e6e6ba !important; */
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%) !important;
}

#rinch-id {
    width: 39px !important;
    height: 39px !important;
}

#inline-span-tag {
    border-radius: 50% !important;
    height: 45px !important;
    width: 45px !important;
    padding: 4px !important;
    /* border: 3px solid #e8e6e6ba !important; */
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%) !important;
    background: #17a2b8
}

#PharmClassImg {
    width: 38px;
    height: 37px;
    border-radius: 50px;
    border: 2px solid white;
}


/* 
  .toggle.ios, .toggle-on.ios, .toggle-off.ios { border-radius: 20rem !important; }
  .toggle.ios .toggle-handle { border-radius: 20rem !important; }

  .toggle.android { border-radius: 0px !important;}
  .toggle.android .toggle-handle { border-radius: 0px !important;  } */

#custom-label label {
    cursor: pointer;
}

#custom-label label:hover {
    color: #A7CA56;
}

#toggleContainer .container {
    text-align: center;
}

.toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    text-align: left;
    top: 8px;
}

.checkbox {
    display: none;
}

.label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
}

.inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.inner:before,
.inner:after {
    float: left;
    width: 50%;
    height: 36px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
}

.inner:before {
    content: "Show";
    /* padding-left: 15px; */
    background-color: #060;
    color: #fff;
    text-align: center;
}

.inner:after {
    content: "Hide";
    padding-right: 10px;
    background-color: rgb(237, 46, 46);
    color: #fff;
    /* text-align: right; */
    text-align: center;
}

.switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}

.checkbox:checked+.label .inner {
    margin-left: 0;
}

.checkbox:checked+.label .switch {
    right: 0px;
}


hr {
    margin-top: 0;
    margin-bottom: 0;
    border-top: 1px solid #52c10d;
    margin-top: 2px;
}

#NewsHr hr {
    margin-top: 0;
    margin-bottom: 0;
    border-top: 1px solid #9F9F9F;
    margin-top: 2px;
}



#paymetHr {
    border-bottom: 2px solid #ffffff !important
}


#taxRate {
    color: #9F9F9F;
}

#contact-submit {
    color: #fff;
    background-color: #a6c550;
    border: #a6c550;
    width: 100px;
    border-radius: 23px;
    padding: 10px;
}


.kENHyg {
    width: 230px !important;
    position: relative !important;
    height: 510px !important;
    display: block !important;
    margin: 15px auto !important;
}

#grapft span {
    font-size: 18px !important;
}

#doseform-badge-id1 {
    font-size: 9px;
}

.rd-navbar-fixed .rd-navbar-collapse-toggle,
.rd-navbar-sidebar .rd-navbar-collapse-toggle {
    z-index: 9999;
    display: none !important;
}

ul#page-numbers li {
    display: inline !important;
    color: #797979;
    float: left;
    padding: 6px 13px;
    text-decoration: none;
    font-size: 13px;
    font-weight: bold;
}

.react-confirm-alert-body>h1 {
    margin-top: 15px !important;
    font-size: 20px !important;
    text-align: center !important;
    color: #13759dfc !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}


div.click-to-top {
    display: inline-block;
    position: relative;
    max-width: 160px;
}

div.click-to-top:hover {
    z-index: 10;
}



div.click-to-top span {
    display: none;
    position: absolute;
    bottom: 3px;
    left: -164px;
    right: 41px;
    background: #15678F;
    color: #fff;
    padding: 10px;
    padding-left: 10px;
    border-radius: 20px;
    width: 164px;
    font-size: 12px;
}

div.click-to-top:hover span {
    display: block;

}

.type-dark {
    background: #15678F !important;
}

.fa-twitter1 {
    color: #fff;
}

.fa-twitter1:hover {
    /* color:hsl(78deg 48% 55%); */
    /* color:#15678f; */
    color: #fff;

}

#crossRefId {
    cursor: pointer;
    color: #15678F !important;
}

#crossRefId:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #15678F !important;
}

#cita-id {
    color: #15678F !important
}

#cita-id:hover {
    color: #a6c550 !important;
    text-decoration: underline !important;
}

#crossRefId:hover {
    color: #a6c550 !important;
    text-decoration: underline !important;
}

.badge-success {
    background: rgb(167, 202, 86) !important;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}