@media print {
    #ddl {
        height: auto !important;
        max-height: 100% !important;
        overflow: visible !important;
    }
}

#search-container {
    /* margin: auto;
    padding: 3em 0 0; */
    /* text-align: center; */
}

#search-container img {
    margin-right: 5px;
    margin-top: -2px;
}

#searchform {
    display: inline;
    font-size: 1em;
    border-radius: 8em;
    border: 0.1em solid #a6c550;
    box-shadow: 0 0 0.3em rgba(60, 60, 60, 0.4);
    padding: 9px 5px;
    background: #fff;
}

#s {
    transition: all 0.2s ease-out;
    width: 1px;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    opacity: 0;
}

#s:focus {
    width: 8em;
    opacity: 1;
}

#search-container label {
    padding-left: 1px;
    display: inline-block;
    margin-top: 0.3em;
    color: rgba(218, 208, 190, 1);
    text-shadow: 0 0 0.1em rgba(60, 60, 60, 0.3);
    position: relative;
    left: 0.1em;
}